import {
  ApptentiveEngageCustomDataProp,
  ApptentivePlugin,
  CordovaApptentivePlugin
} from './types';

declare global {
  interface Window {
    Apptentive?: CordovaApptentivePlugin;
  }
}

export class ApptentiveCordovaPlugin implements ApptentivePlugin {
  enableLog = false;

  constructor(
    private readonly apptentive?: CordovaApptentivePlugin,
    private readonly _permission = false
  ) {
    this.apptentive?.deviceReady(
      () => this.log('deviceReady success'),
      (e) => this.log('deviceReady Error', e)
    );
  }

  get permission() {
    return this._permission;
  }

  private log(msg: string, ...rest: unknown[]) {
    // Todo: revert condition flag when all events are tested
    if (!this.enableLog) {
      window.console.log(`[Apptentive ACP log]: ${msg}`, ...rest);
    }
  }

  private addPersonData(key: string, value: string) {
    this.apptentive?.addCustomPersonData(
      () => {
        this.log('Person custom data added', { key: value });
      },
      (e) => {
        this.log('addPersonData Error', e);
      },
      key,
      value
    );
  }

  sendEvent(
    eventName: string,
    customData?: ApptentiveEngageCustomDataProp,
    logging = false
  ) {
    this.enableLog = logging;
    return new Promise<void>((resolve, reject) => {
      this.apptentive?.engage(
        () => {
          this.addPersonData('customer_id', customData?.customer_id as string);
          this.log('Event Sent Successfully', eventName, customData);
          resolve();
        },
        (e) => {
          this.log('Error (sendEvent): ', e);
          reject();
        },
        eventName
      );
    });
  }
}

export class ApptentiveCordovaNoopPlugin implements ApptentivePlugin {
  permission = false;
  sendEvent() {
    window.console.log(
      `[Apptentive ACP log]: ApptentiveCordovaNoopPlugin.sendEvent called`
    );
    return Promise.resolve();
  }
}
